 .NotFoundPage-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;

   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   position: absolute;
   height: 100vh;
   width: 100vw;
   background-position: 50%;
 }

 .NotFoundPage-link {
   color: #61dafb;
 }